<template>
    <b-container>
        <b-row class="text-center mb-3 mb-lg-5 mt-lg-5">
            <b-col cols="12">
                <h1>Bon cadeau</h1>
            </b-col>
        </b-row>
        <b-row class="text-center" align-v="center">
            <b-col cols="12" lg="6" class="mb-3 mb-lg-0">
                <b-img src="@/assets/gift_card.jpg" fluid-grow />
            </b-col>
            <b-col cols="12" lg="6">
                <p>
                    Envie d’un cadeau pas comme les autres ? Qui profitera aux
                    futurs ou jeunes parents ainsi qu’à leur bébé.<br />
                    <br />
                    J'ai réalisé pour vous, grands-parents, tatas, tontons,
                    parrains, marraines, amis, collègues...<br />
                    Un joli bon que vous n'aurez plus qu'à offrir.<br />
                    <br />
                    Je vous laisse prendre
                    <b-link to="/contact"><em>contact</em></b-link>
                    avec moi pour que je puisse vous guider au mieux sur le
                    choix de votre bon cadeau.<br />
                    <br />
                    Au plaisir de vous rencontrer.<br />
                </p>
            </b-col>
        </b-row>
    </b-container>
</template>

<script>
import NonClickableImg from '../components/NonClickableImg';

export default {
    name: 'GiftCard',
    components: { NonClickableImg },
};
</script>

<style scoped>
#gift_card {
    max-width: 50%;
}
</style>
